import React from "react";
import ExperienceCard from "./Experiences";
import infy from "../Assets/infosys.jpg";


const Experiences = () => {
  return (
    <div
      className="w-full flex flex-col items-center gap-6 mt-8 mx-4"
      id="work-experience"
    >
      <h2 className="text-3xl  text-white font-semibold mb-6">Experience</h2>

      <div className="flex flex-col lg:flex-row gap-2 justify-center w-full">
        <ExperienceCard
          companyName="Infosys"
          position="System Engineer"
          duration="Sep 2021 - Dec 2022"
          //   location="Houston, TX"
          bgColor="bg-gray-800"
          logo={infy}
        />
        <ExperienceCard
          companyName="Sigma Info Solutions"
          position="Junior Software Engineer"
          duration="Sep 2020 - Aug 2021"
          location="Bengaluru, India"
          bgColor="bg-gray-600"
          logo="https://www.sigmais.com/wp-content/uploads/2020/06/sigma-logo.png"
        />

       
      </div>
    </div>
  );
};

export default Experiences;
